angular.module('CaresApp').directive('ignoreDirty', function() {
  return {
    restrict: 'A',
    require: ['ngModel'],
    link: function(_, __, ___, ctrl) {
      ctrl.$setPristine = function() {};
      ctrl.$pristine = true;
    },
  };
});
