angular.module('CaresApp').directive('ocrField', function() {
  return {
    require: ['ngModel', '?^quickInput', '?^form'],
    link: function(_, __, attrs, ctrls) {
      const [ngModelCtrl, quickInputCtrl, ngFormCtrl] = ctrls;
      // Give the input its name
      ngModelCtrl.$name = attrs.id;
      // Tell the form all about it
      if (ngFormCtrl) {
        ngFormCtrl.$addControl(ngModelCtrl);
      }
      if (quickInputCtrl) {
        quickInputCtrl.setLabel(attrs.title);
        quickInputCtrl.setId(attrs.id);
        quickInputCtrl.setModelCtrl(ngModelCtrl);
      }
    },
  };
});

