angular.module('CaresApp').directive(function() {
  return {
    scope: true,
    replace: true,
    transclude: true,
    template: '<div class="form-group"><label for="{{id}}">'
    + '{{label}}</label><div ng-transclude></div>'
    + '<ul ng-if="modelCtrl.$invalid && modelCtrl.$touched">'
    + '<li class="error" ng-if="modelCtrl.$error.required || modelCtrl.$error.parse">'
    + 'This field is required.</li><li class="error" ng-show="modelCtrl.$error.pattern '
    + '&& format.patternError">{{format.patternError}}</li></ul></div>',
    controller: function($scope) {
      this.setFormatting = function(format) {
        $scope.format = format;
      };

      this.setLabel = function(label) {
        $scope.label = label;
      };

      this.setId = function(id) {
        $scope.id = id;
      };

      this.setModelCtrl = function(modelCtrl) {
        $scope.modelCtrl = modelCtrl;
      };
    },
  };
});


