angular.module('CaresApp').directive("ocrMoney", function($filter, $locale) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, _, attr, ctrl) {
      // format on init
      formatMoney();

      function formatMoney() {
        let value = ctrl.$modelValue;

        // remove all separators first
        const groupsep = $locale.NUMBER_FORMATS.GROUP_SEP;
        const re = new RegExp(groupsep, 'g');
        value = String(value).replace(re, '');

        // format using angular
        const currencyFilter = $filter('currency');
        value = currencyFilter(value, "");

        // sorry but no cents
        const decimalsep = $locale.NUMBER_FORMATS.DECIMAL_SEP;
        [value] = value.split(decimalsep);

        // render
        ctrl.$viewValue = value;
        ctrl.$render();
      }

      // subscribe on changes
      scope.$watch(attr.ngModel, function() {
        formatMoney();
      });
    },
  };
});

