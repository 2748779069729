angular.module('CaresApp').directive('ocrFormat',
  [
    '$filter',
    '$parse',
    'formatFactory',
    function($filter, $parse, formatFactory) {
      return {
        scope: true,
        restrict: 'A',
        require: ['ngModel', '?^quickInput'],
        link: function(scope, element, attrs, ctrls) {
          const [ngModelCtrl, quickInputCtrl] = ctrls;
          let filterName = attrs.ocrFormat
          let filterArgs = []
          //let formatArgs = attrs.ocrFormat.split(/\s*:\s*/, 2)
          const filterNameIdx = attrs.ocrFormat.indexOf(':')
          if (filterNameIdx !== -1) {
            filterName = attrs.ocrFormat.substring(0, filterNameIdx)
            const argsStr = attrs.ocrFormat.substring(filterNameIdx + 1).replace(':', ',')
            filterArgs = $parse(`[${argsStr}]`)(scope)
          }
          filterName = filterName.replace(/\s/, '')
          const thisFormat = formatFactory[filterName]

          // This is the toModel routine
          const parser = function(value) {
              if (value || value === 0) {
                value = value.toString().replace(/\$/g, '');
              }
              if (!value && value !== 0) {
                return null;
              }
              // get rid of currency indicators
              value = value.toString().replace(thisFormat.replace, '');
              // Check for parens, currency filter (5) is -5
              const removeParens = value.replace(/[()]/g, '');
              // having parens indicates the number is negative
              if (value.length !== removeParens.length) {
                value = -removeParens;
              }
              return value || null;
            },
            // This is the toView routine
            formatter = function(value) {
              filterArgs.unshift(parser(value))
              const filter = $filter(filterName)
              const formattedValue = filter.apply(filter, filterArgs)
              filterArgs.shift()
              // the currency filter returns undefined if parse error
              return formattedValue || thisFormat.symbol || '';
            };

          // This sets the format/parse to happen on blur/focus
          element.on("blur", function() {
            ngModelCtrl.$setViewValue(formatter(this.value));
            ngModelCtrl.$render();
          }).on("focus", function() {
            ngModelCtrl.$setViewValue(parser(this.value));
            ngModelCtrl.$render();
          });

          // Model Formatter
          ngModelCtrl.$formatters.push(formatter);
          // Model Parser
          ngModelCtrl.$parsers.push(parser);

          if (quickInputCtrl) {
            quickInputCtrl.setFormatting(thisFormat);
          }
        },
      }
    },
  ],
);

